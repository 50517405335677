body,
html {
  font-family: "Nunito", sans-serif !important;
}

.cust-name {
  font-size: 18px;
}

.cust-profession {
  font-size: 10px;
}

.profile {
  margin-top: 16px;
  margin-left: 11px;
}

.profile-pic {
  width: 58px;
}
